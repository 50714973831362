// extracted by mini-css-extract-plugin
export var container = "product-module--container--2i59b";
export var title = "product-module--title--lHn_F";
export var price = "product-module--price--3iYnq";
export var image = "product-module--image--2HPH9";
export var imageContainer = "product-module--imageContainer--1doUZ";
export var mainImageContainer = "product-module--mainImageContainer--UGsVy";
export var imageThumbNailContainer = "product-module--imageThumbNailContainer--2BUQV";
export var imageThumbNail = "product-module--imageThumbNail--s1oy9";
export var imageThumbNailActive = "product-module--imageThumbNailActive--3WsUl";
export var addToBagButton = "product-module--addToBagButton--1BEhK";
export var addToBagButtonActive = "product-module--addToBagButtonActive--2wOGk";
export var addToBagButtonDisabled = "product-module--addToBagButtonDisabled--1cUe6";
export var buttonIcon = "product-module--buttonIcon--AOWxM";
export var nextPicture = "product-module--nextPicture--3bEoc";
export var previousPicture = "product-module--previousPicture--fMGz-";